@import 'variables';

/* Icons */

%svg-common {
    display: inline-block;
}

.icon-logout {
    transform: scale(1.5) rotate(135deg);
}

.accountchooser {
    .list-group-item {
        .media-right .icon {
            /* arrow-right */
            @extend .icon-arrow-right;
        }
    }
    &.mode-remove {
        .list-group-item {
            //&:hover,
            //&:focus {
            //    border: $border-col-tertiary 2px solid;
            //}
            .media-right .icon {
                /* cross-hover */
                @extend .icon-cross;
            }
            &:hover,
            &:focus {
                .media-right .icon {
                    /* cross-hover */
                    @extend .icon-cross-hover;
                }
            }
        }
        a:focus,
        a:active,
        a:hover {
            .media-right .icon {
                /* cross-hover */
                @extend .icon-cross-hover;
            }
        }
    }
}
