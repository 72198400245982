@import 'variables';

// Styles for buttons

button:disabled {
  background-color: $bg-col-btn-secondary;
  cursor: not-allowed;
  &:hover {
    background-color: $bg-col-btn-secondary-dark;
    cursor: not-allowed;
  }
}

button, .button {
  background-color: $bg-col-btn-primary;
  border: 0;
  color: $font-color;
  cursor: pointer;
  display: block;
  font: 400 1rem/1.25rem 'Muli';
  margin-bottom: 1rem;
  padding: 0 1rem;
  text-align: center;
  transition: background-color 0.5s ease;
  height: 4.125rem;
  width: 100%;
  &::-moz-focus-inner {
      border: 0;
  }
}

.button {
  line-height: 4.125rem;
}

button.inline, .button.inline {
  display: inline-block;
  width: auto;
}

button.small, .button.small {
  height: 2.0625rem;
}

button.spaced, .button.spaced {
  margin: 1rem 0;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  background-color: $bg-col-btn-primary-dark;
  color: $font-color-light;
  outline: 0;
}

button.secondary, .button.secondary {
  background-color: $bg-col-btn-secondary;
}

button.secondary:hover,
button.secondary:focus,
.button.secondary:hover,
.button.secondary:focus {
  background-color: $bg-col-btn-secondary-dark;
}

a.button {
  color: $h2-color;
  text-decoration: none;
}

.button-row {
  text-align: justify;
  margin-bottom: 1em;
  margin-top: 1em;
  max-height: 4.125rem;
}

.button-row:after {
  content: '';
  width: 100%;
  display: inline-block;
}

.button-row > .button, .button-row > button {
  display: inline-block;
  vertical-align: top;
}

.two-button-row > .button, .two-button-row > button {
  width: 48%;
}

.three-button-row > .button, .three-button-row > button {
  width: 31%;
}