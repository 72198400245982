@import 'variables';

// Tooltip (info icon) styles

// This is a button, but made more sense to place here together with other tooltip styles,
// instead of in buttons.scss
.tooltip,
.more-info-tooltip {
  background-color: transparent;
  border: 0;
  display: inline;
  margin-bottom: 1px;
  margin: 0;
  margin-left: 5px;
  padding: 2px;
  outline: 0;
  height: auto;
  width: auto;
  .icon {
    border-radius: 12px;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    border: 1px solid  $border-col-primary-dark;;
    padding: 1px;
    outline: 0;
    .icon {
      box-shadow: 0 0 0 2px $border-col-primary-dark;
    }
  }
}

#more-information-tooltip {
  display: none;
  visibility: visible;
  align-items: center;
}

.tooltip-bubble,
.affiliation-tooltip {
  visibility: hidden;
  width: 325px;
  background-color: #444444;
  color: #fff;
  border-radius: 6px;
  margin-left: -74px;
  line-height: 1.6;
  /* Position the tooltip */
  position: absolute;
  z-index: 0;
  text-align: inherit;
  padding: 0.2rem 0.5rem 0rem 0.5rem;
  margin-top: 7px;
  &::after {
    content: "";
    visibility: visible;
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 89px;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #444444 transparent;
  }
}

//OLD TOOLTIP
// Tooltip (info icon) styles. Should be a button
.tooltip-old, .more-info-tooltip-old {
    background-color: transparent;
    border: 0;
    display: inline;
    margin: 0;
    padding: 2px;
    outline: 0;
    height: auto;
    width: auto;
    .icon {
        border-radius: 12px;
    }
    &:hover, &:focus {
        background-color: transparent;
        outline: 0;
        .icon {
            box-shadow: 0 0 0 2px $border-col-primary-dark;
        }
    }
}

.tooltip-bubble-old{
    background-color: $bg-col-primary-light;
    padding: .5rem 2rem .5rem 1rem;
    position: relative;
    margin-bottom: .5rem;
    &:after {
        @extend .icon-small-cross;
        content: "";
        cursor: pointer;
        position: absolute;
        right: .5rem;
        top: .5rem;
    }
}
