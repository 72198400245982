@import 'variables';

.list-group {
    margin-bottom: 1em;
    padding: 0;
}

.list-group-item {
    border: 2px solid $border-col-primary-light;
    margin-bottom: -2px;
    padding: 0.5rem 1rem;
    position: relative;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
        a {
            cursor: not-allowed;
        }
    }
}

.list-group.media {
    .list-group-item {
        padding: 0;
        button:focus,
        button:active,
        button:hover,
        a:focus,
        a:active,
        a:hover {
            > div {
                border: 2px solid $border-col-primary-light;
            }
        }
        &.disabled {
            button:focus,
            button:active,
            button:hover,
            a:focus,
            a:active,
            a:hover {
                > div {
                    border: 0;
                }
            }
        }
    }
    &.secondary {
        .list-group-item {
            border-color: $border-col-secondary;
            button:focus,
            button:active,
            button:hover,
            a:focus,
            a:active,
            a:hover {
                > div {
                    border: 2px solid $border-col-secondary;
                }
            }
        }
    }
    .media {
        margin-bottom: 0;
    }
}

@supports (outline-offset: -2px) {
    .list-group.media {
        .list-group-item {
            button:focus,
            button:active,
            button:hover,
            a:focus,
            a:active,
            a:hover {
                > div {
                    border: 0;
                    outline: $border-col-primary-light solid 2px;
                    outline-offset: -2px;
                }
            }
            &.disabled {
                button:focus,
                button:active,
                button:hover,
                a:focus,
                a:active,
                a:hover {
                    > div {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }
        &.secondary {
            .list-group-item {
                button:focus,
                button:active,
                button:hover,
                a:focus,
                a:active,
                a:hover {
                    > div {
                        border: 0;
                        outline: $border-col-secondary solid 2px;
                        outline-offset: -2px;
                    }
                }
            }
        }
    }
}

.list-group-item > a,
.list-group-item > button {
    color: $color-list-group-item-button;
    font-size: 1rem;
    line-height: 1.25rem;
    text-decoration: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.list-group-item .media-left {
    height: 42px;
}

.list-group-item .media-left img {
    max-width: 50px;
    max-height: 50px;
}

.list-group-item .media-body {
    position: relative;
    vertical-align: middle;
    text-align: left;
}

/* Bullet list */

ul.bullet-list {
    list-style-type: disc;
    list-style-position: outside;
    margin: 0 0 1rem 1rem;
    li > ul {
        margin-bottom: 0;
    }
}

/* Api-scope list */

.list-api-scopes {
    margin-bottom: 1rem;
}

/* Definition list */

dl {
    margin-bottom: 0.5rem;
}

dt {
    font-weight: 600;
}


.accountchooser .list-group-item .media-left {
    width: 50px;
    height: 50px;
    background: $bg-col-primary-light;
    border-radius: 50%;
    text-align: center;
    img {
        border-radius: 50%;
        &.icon-avatar {
            border-radius: 0;
        }
    }
    i.logout-icon {
        display: inline-block;
        transform: scale(1.3) rotate(135deg);
    }
}

.accountchooser .list-group-item.active .media-left {
    border: solid 3px $color-avatar-border;
}

.login-providers.dim {
    opacity: 0.3;
}

.login-providers .list-group-item .media-left {
    width: 42px;
    height: 42px;
    i {
        background-size: 100% auto;
        width: 42px;
        height: 42px;
    }
}

.list-group-item .media-body {
    position: relative;
    vertical-align: middle;
    text-align: left;
}

