@import 'variables';

.discoveryservice-affiliation-header {
  color: $font-color;
  font-size: 1.0rem;
  font-weight: bold;
  margin-bottom: 1px;
  display: flex ;
  > div {
    font-weight: 400;
  }
}

.recents_label_placeholder {
  display: none;
}

.recents_label {
  display: block;
  font-weight:bold;
  text-align: left;
  margin-left: 10px;
  font-size: 0.8rem;
}

.recents_label_search_hide {
  display: none;
}

.recent_template {
  display: none;
}

.org-header {
  border-bottom: 2px solid $bg-col-body;
  margin: 0 0 .6875rem 0;
}

.breathe-top {
  margin-top: .5em;
}

.data_article{
  display: none;
  padding-bottom: 1px;
}

#orglist_wrapper {
  max-height: 165px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 2px 4px 0 rgba(102, 102, 102, 0.2), 0 4px 5px 0 rgba(102, 102, 102, 0.01)
}

#orglist {
  border: 0 transparent $bg-col-btn-primary-dark;
  border-width: 0 0 2px 2px;
  margin-top: 0px;
  display: none;
}

.last_recent{
  border-bottom: 2px solid $bg-col-body !important;
}

.orglist_item{
  cursor: pointer;
  user-select: none;
  border: 0 transparent $bg-col-btn-primary-dark;
  border-width: 2px 2px 0 0;
  padding: 10px;
  font-size: 18px;
  &:focus {
      background-color:   #f4f4f4;
  }
  &:hover {
    background-color: #f4f4f4;
}
  &.chosen {
      background-color: $bg-col-body;
  }
}

.orglist_item_wrapper {
  display: flex;
  align-items: center;
}

.recents_label{
  font-weight:bold;
  text-align: left;
  margin-left: 10px;
  font-size: 0.8rem;
}

.orglist_logo {
  max-height: 34px;
  max-width: 34px;
}

.orglist_logo_wrapper {
  height: 34px;
  width: 34px;
  justify-items: center;
  align-items: center;
  display: grid;
  margin-right: 15px;
}

#hit-amount {
  margin-top: 8px;
  width: 1px;
  height: 1px;
}

.hit-label {
  text-align: right;
  float: right;
  width: 1px;
  height: 1px;
  position: absolute;
  margin-bottom: -1px;
  margin-right: -1px;
  clip: rect(0px 0px 0px 0px);
}

#hits-found {
  text-align: right;
  color: grey;
  font-size: small;
  float: right;
  width: 100%;
}

.more-info-button{
  border-radius: 50%;
  background-color: #283678;
  color: white;
  font-weight: 800;
  min-width: 20px;
  padding-right: 1px;
}

// Discovery service styles

.affiliation-discovery-form {
    margin-bottom: 1.5rem;
}

// /*
//  * Horizontal design of login providers .
//  * First design for browsers that do not support flex.
//  * Then design for browsers that support flex, overriding styles as necessary
//  */
.login-providers_bak {
    overflow: visible;
    text-align: center;
    > li {
        float: left;
        margin-right: 0.5rem;
        width: 4rem;
        button {
            background-color: transparent;
            height: auto;
            width: auto;
            padding: 0.2rem;
            margin: 0 auto;
            &:focus,
            &:hover {
                outline: 2px solid $border-col-primary-dark;
            }
        }
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.login-providers {
    .list-group-item > a {
        display: block;
    }
    .list-group-item {
        .section-list {
            .media {
                display: flex;
                align-items: center;
                .media-left {
                    padding-top: 4px;
                }
            }
        }
    }
}

.accountchooser .list-group-item .media-left {
    width: 50px;
    height: 50px;
    background: $bg-col-primary-light;
    border-radius: 50%;
    text-align: center;
    img {
        border-radius: 50%;
        &.icon-avatar {
            border-radius: 0;
        }
    }
    i.logout-icon {
        display: inline-block;
        transform: scale(1.3) rotate(135deg);
    }
}

.accountchooser .list-group-item.active .media-left {
    border: solid 3px $color-avatar-border;
}

.login-providers.dim {
    opacity: 0.3;
}

.login-providers .list-group-item .media-left {
    width: 42px;
    height: 42px;
    i {
        background-size: 100% auto;
        width: 42px;
        height: 42px;
    }
}

@supports (display: flex) {
    .login-providers_bak {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        > li {
            float: none;
            margin: 0;
        }
        &:after {
            content: none;
        }
    }
}

@media (max-width: 26.5625em) {
    #wrapper {
        margin: 0 auto;
    }
}

@media (max-width: 20em) {
    html {
        font-size: 90%;
    }
}

// Client header block
.client-header {
    border-bottom: 2px solid $bg-col-body;
    margin-bottom: 8px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .left-section {
        width: 100%;
    }
    .right-section {
        width: 100%;
    }
}
