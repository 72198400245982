@import 'variables';

/* Media objects */

.item {
    .media {
        display: flex;
        padding: 0.38rem;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .media-left {
            display: block;
            white-space: nowrap;
            text-align: center;
            .helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
            img {
                max-width: 42px;
                max-height: 42px;
                width: auto !important;
                height: auto;
                vertical-align: middle;
            }
        }
        .media-body {
            display: block;
            text-align: center;
        }
    }
}

.media {
    background-color: $bg-col-default;
    display: table;
    padding: 0.6875rem;
    margin-bottom: 1rem;
    width: 100%;
}

.media-left {
    display: table-cell;
    min-height: 50px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    text-align: center;
}

.media > .media-left > img {
    vertical-align: middle;
    max-width: 50px;
}

.media > .media-body {
    display: table-cell;
    padding-left: 1em;
    padding-right: 1em;
    vertical-align: top;
}

.media > .media-body.align-middle {
    vertical-align: middle;
}

.media > .media-body h1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0.3125rem;
}

.media .media-right {
    min-width: 14px;
    width: 14px;
    height: 24px;
    display: table-cell;
    vertical-align: middle;
}
