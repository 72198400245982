@import 'variables';

.color-body {
    background-color: $bg-col-body;
}

.color-header {
    background-color: $bg-col-header;
}

.color-default {
    background-color: $bg-col-default;
}

.color-panel {
    background-color: $bg-col-panel;
}

.color-button-primary {
    background-color: $bg-col-btn-primary;
}
.color-button-primary-dark {
    background-color: $bg-col-btn-primary-dark;
    color: $font-color-light;
}
.color-button-secondary {
    background-color: $bg-col-btn-secondary;
}
.color-button-secondary-dark {
    background-color: $bg-col-btn-secondary-dark;
    color: $font-color-light;
}

.color-primary-dark {
    background-color: $bg-col-primary-dark;
}

.color-primary-light {
    background-color: $bg-col-primary-light;
}

.color-secondary-dark {
    background-color: $bg-col-secondary-dark;
}

.color-secondary-light {
    background-color: $bg-col-secondary-light;
}

.color-tertiary {
    background-color: $bg-col-tertiary;
}

.color-border-primary-dark {
    background-color: $border-col-primary-dark;
}

.color-border-primary-light {
    background-color: $border-col-primary-light;
}

.color-border-secondary {
    background-color: $border-col-secondary;
}

.color-border-tertiary {
    background-color: $border-col-tertiary;
}

.color-font {
    background-color: $font-color;
}
.color-h2 {
    background-color: $h2-color;
}
.color-h1 {
    background-color: $h1-color;
}
.color-link {
    background-color: $link-color;
}
