@import 'variables';

////
// All styles related to language selection.
// It is not placed under module/ because it has styles placed in multiple location.
////

#language-chooser-wrapper {
  float: right;
  position: relative;
}

#language_selector {
  display: none;
}

.lang-dropdown-button {
  cursor: pointer;
  width: 153px;
  line-height: 40px;
  float: right;

  &:focus {
    background-color: #606CA5;
    color: #fff;
  }
}

.lang-option-wrapper {
  float: right;
  padding-right: 42px;
}

.lang-dropdown-button.closed:after {
  @extend .icon-arrow-down-small;
  content: '';
  float: right;
  margin-top: -25px;
  margin-right: 10px;
}

.lang-dropdown-button.open:after {
  @extend .icon-arrow-up-small;
  content: '';
  float: right;
  margin-top: -25px;
  margin-right: 10px;
}

.lang-dropdown-list {
  background-color: #fff;
  border-left: 2px solid #606CA5;
  border-right: 2px solid #606CA5;
  border-top: 2px solid #606CA5;
  display: none;
  width: 150px;
  z-index: 2;
  position: absolute;
}

.lang-dropdown-lang {
  border-bottom: 2px solid #606CA5;
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #C5D4E7;
  }

  &:focus {
    background-color: #C5D4E7;
  }
}

.lang-name {
  padding-left: 10px;
  min-width: 70px;
  display: inline-block
}

// For the language chooser
#language-chooser-wrapper {
  height: 2.5rem;
}

.language-chooser .selectize-input {
  background-color: $bg-col-header !important;
  border: 0;
  box-shadow: none;
}

.language-chooser .selectize-input.input-active,
.language-chooser .selectize-input.input-active input {
  cursor: pointer !important;
}

#language-chooser-selectized {
  height: inherit;
}

#language-chooser-form {
  height: 100%;
}
