%svg-common {
    background: url("../media/sprite-a368f429.svg") no-repeat;
}

.icon-arrow-down {
    @extend %svg-common;
    background-position: 0 0;
    width: 24px;
    height: 14.72px;
}

.icon-arrow-down-small {
    @extend %svg-common;
    background-position: 0 1.3254278923045657%;
    width: 18px;
    height: 11.29px;
}

.icon-arrow-right {
    @extend %svg-common;
    background-position: 0 2.415026833631485%;
    width: 13.72px;
    height: 25px;
}

.icon-arrow-up {
    @extend %svg-common;
    background-position: 0 4.60878505335555%;
    width: 24px;
    height: 14.72px;
}

.icon-arrow-up-small {
    @extend %svg-common;
    background-position: 0 5.92024458562706%;
    width: 18px;
    height: 11.29px;
}

.icon-avatar {
    @extend %svg-common;
    background-position: 0 7.085201793721973%;
    width: 26px;
    height: 28px;
}

.icon-checkbox-checked {
    @extend %svg-common;
    background-position: 0 9.66576332429991%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-checked-filled {
    @extend %svg-common;
    background-position: 0 12.917795844625113%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-error-checked {
    @extend %svg-common;
    background-position: 0 16.169828364950316%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-error-checked-filled {
    @extend %svg-common;
    background-position: 0 19.42186088527552%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-error-unchecked {
    @extend %svg-common;
    background-position: 0 22.673893405600722%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-error-unchecked-filled {
    @extend %svg-common;
    background-position: 0 25.925925925925927%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-small-checked {
    @extend %svg-common;
    background-position: 0 29.204339963833636%;
    width: 22px;
    height: 37px;
}

.icon-checkbox-small-unchecked {
    @extend %svg-common;
    background-position: 0 32.5497287522604%;
    width: 22px;
    height: 37px;
}

.icon-checkbox-unchecked {
    @extend %svg-common;
    background-position: 0 35.86269196025294%;
    width: 35px;
    height: 36px;
}

.icon-checkbox-unchecked-filled {
    @extend %svg-common;
    background-position: 0 39.11472448057814%;
    width: 35px;
    height: 36px;
}

.icon-cross {
    @extend %svg-common;
    background-position: 0 41.95103625320894%;
    width: 24.11px;
    height: 25.03px;
}

.icon-cross-hover {
    @extend %svg-common;
    background-position: 0 44.27668005402649%;
    width: 24.11px;
    height: 25.03px;
}

.icon-edugain {
    @extend %svg-common;
    background-position: 0 47.71062271062271%;
    width: 41.4px;
    height: 51px;
}

.icon-eidas {
    @extend %svg-common;
    background-position: 0 52.38095238095238%;
    width: 41.4px;
    height: 51px;
}

.icon-failure {
    @extend %svg-common;
    background-position: 0 55.625%;
    width: 22px;
    height: 23px;
}

.icon-feide {
    @extend %svg-common;
    background-position: 0 59.15750915750916%;
    width: 41.3px;
    height: 51px;
}

.icon-flag-en {
    @extend %svg-common;
    background-position: 0 61.80447794280647%;
    width: 19px;
    height: 15.25px;
}

.icon-flag-nb {
    @extend %svg-common;
    background-position: 0 63.22323209931279%;
    width: 19px;
    height: 15.25px;
}

.icon-flag-nn {
    @extend %svg-common;
    background-position: 0 64.6419862558191%;
    width: 19px;
    height: 15.25px;
}

.icon-flag-se {
    @extend %svg-common;
    background-position: 0 66.05254058463147%;
    width: 19px;
    height: 15.11px;
}

.icon-globe {
    @extend %svg-common;
    background-position: 0 67.76491540516474%;
    width: 19px;
    height: 20px;
}

.icon-helseid {
    @extend %svg-common;
    background-position: 0 71.52014652014653%;
    width: 50px;
    height: 51px;
}

.icon-idporten {
    @extend %svg-common;
    background-position: 0 76.19047619047619%;
    width: 50px;
    height: 51px;
}

.icon-info {
    @extend %svg-common;
    background-position: 0 78.83928571428571%;
    width: 22px;
    height: 23px;
}

.icon-linkedin {
    @extend %svg-common;
    background-position: 0 82.96703296703296%;
    width: 50px;
    height: 51px;
}

.icon-loading2 {
    @extend %svg-common;
    background-position: 0 87.63736263736264%;
    width: 50px;
    height: 51px;
}

.icon-logout {
    @extend %svg-common;
    background-position: 0 89.78675645342311%;
    width: 26px;
    height: 20.34px;
}

.icon-minus {
    @extend %svg-common;
    background-position: 0 91.71122994652407%;
    width: 20px;
    height: 21px;
}

.icon-plus {
    @extend %svg-common;
    background-position: 0 93.58288770053476%;
    width: 20px;
    height: 21px;
}

.icon-small-cross {
    @extend %svg-common;
    background-position: 0 95.45454545454545%;
    width: 20px;
    height: 21px;
}

.icon-tsd {
    @extend %svg-common;
    background-position: 0 100%;
    width: 50px;
    height: 51px;
}

