@import 'variables';

/* utilities */

.hidden {
    display: none !important;
}

.not-visible {
    visibility: hidden;
}

[aria-hidden="true"] {
    display: none !important;
}

.show-for-sr {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.desaturate {
    filter: grayscale(1);
}

.left {
    float: left;
}

.boxes {
    &.white {
        color: white;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    > div {
        font-size: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        border: 1px solid black;
        padding: 0.2rem;
        width: 100px;
        height: 100px;
    }
}

.clear {
    clear: both;
}

.right {
    float: right;
}

strong {
    font-weight: bold;
}

/* Style for centering a right arrow - remember position relative on parent */
.right-arrow {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
}

