@import 'variables';

// Styles for page setup or that affects large parts of the page.
// Normally, styles that do not target a specific class-name or id-selector.


* {
    box-sizing: border-box;
}

pre,
code,
kbd,
samp,
tt {
    font-family: monospace, monospace;
    font-size: 0.9rem;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0 0.2rem;
}

pre {
    padding: 0.5rem 1rem;
}

em {
    font-style: italic;
}

html {
    font-size: 100%;
}

body {
    background-color: $bg-col-body;
    color: $font-color;
    font-family: "Muli", sans-serif;
    line-height: 1.25rem;
    overflow: auto; // Workaround for embedded Internet Explorer 11 in Office 365 desktop applications on Windows 7, which doesn't allow scrolling.
}

article {
    padding: 1em;
}

#language-chooser-wrapper {
    float: right;
    position: relative;
}

#wrapper {
    background-color: $bg-col-default;
    max-width: 502px;
    margin: 1em auto;
}

#main-head {
    background-color: $bg-col-header;
    padding: 0.3em;
}

footer {
    background-color: $bg-col-footer;
    padding: 2em;
    text-align: center;
}

footer p {
    margin-bottom: 0px;
}

p {
    margin-bottom: 0.5em;
}

// Styling for the TOU-page
#tou p {
    margin-bottom: 1rem;
}

strong {
    font-weight: bold;
}

.left {
    float: left;
}

.right {
    float: right;
}

// Styling for the TOU-page
#tou p {
    margin-bottom: 1rem;
}

strong {
    font-weight: bold;
}

.left {
    float: left;
}

.right {
    float: right;
}

.subtitle {
    margin-top: 1.5rem;
}


/* Styles for headings */

h1 {
    color: $h1-color;
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 2.875rem;
    margin-bottom: 0.5rem;
}

h2 {
    color: $h2-color;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5625rem;
    margin-bottom: 0.5rem;
}

h3 {
    font-weight: bold;
    margin-bottom: 0.2rem;
}

/* Link styles */

small {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

a {
    color: $link-color;
    &:focus,
    &:hover {
        background-color: $link-focus-color;
        outline: 0;
    }
    &.backlink {
        display: none;
    }
}
