@import 'variables';

/* General selectize styles */

.selectize-control {
    .item,
    .option {
        .flag-wrapper {
            display: flex;
            align-items: center;
            .flag {
                margin-right: 0.4rem;
                height: 14px;
                width: 19px;
            }
        }
    }
    .selectize-input input {
        margin-right: 0px !important;
        width: 0 !important;
    }
}

.selectize-input,
.selectize-input input,
.selectize-dropdown {
    font-family: "Muli", sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
}

.selectize-input {
    border: 2px solid $border-col-primary-light;
    border-radius: 0;
    box-shadow: none;
    padding-right: 2.2em;
}

.selectize-input.dropdown-active {
    border-radius: 0;
    box-shadow: none;
}

.selectize-dropdown {
    border: 0;
    min-width: 10em !important;
    left: auto !important;
    right: 0 !important;
}

.selectize-dropdown:hover,
.selectize-dropdown:focus {
    cursor: pointer;
}

.selectize-dropdown .active {
    background-color: $bg-col-default;
    color: $font-color;
}

.selectize-dropdown-content {
    max-height: 226px;
}

.selectize-dropdown-content .option {
    border: 2px solid $border-col-primary-light;
    margin-bottom: -2px;
    padding: 0.5em;
}

.selectize-dropdown-content .option:last-child {
    margin-bottom: 0;
}

.selectize-control.single .selectize-input:after {
    /* arrow-down */
    @extend .icon-arrow-down-small;
    border: 0;
    content: "";
    position: absolute;
    right: 0.625rem;
    margin-top: -4px;
}

.selectize-control.single .selectize-input.dropdown-active:after {
    /* arrow-down (mirrored) */
    @extend .icon-arrow-up-small;
}

.media-object.selectize-control.single .selectize-input:after {
    /* arrow-down */
    @extend .icon-arrow-down;
    border: 0;
    content: "";
    position: absolute;
    right: 0.625rem;
    margin-top: -6px;
}

.media-object.selectize-control.single .selectize-input.dropdown-active:after {
    @extend .icon-arrow-up;
}

/* For IE */
.selectize-dropdown-content .option.active {
    border-width: 4px;
}

@supports (outline-offset: -4px) {
    .selectize-dropdown-content .option.active {
        border-width: 2px;
        outline: 2px solid $border-col-primary-light;
        outline-offset: -4px;
    }
}

/* For the language chooser */
#language-chooser-wrapper {
    float: right;
    position: relative;
}

#language_selector {
    display: none;
}

.lang-dropdown-button {
    cursor: pointer;
    width: 153px;
    line-height: 40px;
    float: right;

    &:focus {
        background-color: #606ca5;
        color: #fff;
    }
}

.lang-option-wrapper {
    float: right;
    padding-right: 42px;
}

.lang-dropdown-button.closed:after {
    @extend .icon-arrow-down-small;
    content: "";
    float: right;
    margin-top: -25px;
    margin-right: 10px;
}

.lang-dropdown-button.open:after {
    @extend .icon-arrow-up-small;
    content: "";
    float: right;
    margin-top: -25px;
    margin-right: 10px;
}

.lang-dropdown-list {
    background-color: #fff;
    border-left: 2px solid #606ca5;
    border-right: 2px solid #606ca5;
    border-top: 2px solid #606ca5;
    display: none;
    width: 150px;
    z-index: 2;
    position: absolute;
}

.lang-dropdown-lang {
    border-bottom: 2px solid #606ca5;
    height: 36px;
    line-height: 36px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: #c5d4e7;
    }

    &:focus {
        background-color: #c5d4e7;
    }
}

.lang-name {
    padding-left: 10px;
    min-width: 70px;
    display: inline-block;
}

#language-chooser-wrapper {
    height: 2.5rem;
}
.language-chooser .selectize-input {
    background-color: $bg-col-header !important;
    border: 0;
    box-shadow: none;
}

.language-chooser .selectize-input.input-active,
.language-chooser .selectize-input.input-active input {
    cursor: pointer !important;
}

#language-chooser-selectized {
    height: inherit;
}

/* For selectize with media objects as options */

.media-object .selectize-input {
    height: 4.125rem;
    padding: 0;
}

.media-object .selectize-input input {
    height: 100%;
    padding-left: 1rem !important;
    width: 100% !important;
}

.media-object .selectize-input .item .media-left {
    height: 42px;
}

.media-object .selectize-input .item .media-left img {
    width: 42px;
}

.media-object .selectize-dropdown-content .option {
    padding: 0;
}

.media-object .media {
    margin: 0;
}

.media-object .selectize-dropdown-content .option .media-left {
    height: 32px;
    width: 32px;
}

.media-object .selectize-dropdown-content .option .media-left img {
    width: 32px;
}

.toggle-button {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    height: auto;
    text-align: left;
    &:focus,
    &:hover {
        background-color: transparent;
        outline: 0;
    }
}
